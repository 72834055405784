<template>
  <admin-wrapper>
        <v-snackbar v-model="error" type="error">
          {{errorMessage}}
        </v-snackbar>
        <app-loader :value="$apollo.loading"/>

        <v-card flat>
            <v-container>
                <nice-header>
                    Create a new store
                </nice-header>

                <span v-if="(subscription && subscription.plan.product.name === 'Advanced') || accountData.isSumoUser || accountData.ltdCustomer">
                  <v-card-text>
                    <p>Enter a name below and click the save button to create a new store</p>
                    <v-form v-model="valid">
                      <v-text-field rounded outlined v-model="form.name" label="Store name" :rules="required"></v-text-field>
                      <v-autocomplete
                        v-model="form.currency"
                        :items="currencies"
                        label="Currency"
                        item-value="identifier"
                        :rules="required"
                        outlined
                        rounded
                    >
                        <template v-slot:item="{ item }">
                            {{item.identifier.toUpperCase()}} ({{item.symbol}})
                        </template>
                        <template v-slot:selection="{ item }">
                            {{item.identifier.toUpperCase()}} ({{item.symbol}})
                        </template>
                    </v-autocomplete>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined rounded color="success" :disabled="!valid" :loading="loading" @click="createStore">Save</v-btn>
                  </v-card-actions>

                </span>
                <v-card-text v-else>
                  <v-alert type="warning" outlined>
                    Advanced account required
                  </v-alert>
                  This is a premium feature - In order to create more than one store, you must first
                  <router-link to="/account">Upgrade your account</router-link>
                </v-card-text>
            </v-container>
        </v-card>
  </admin-wrapper>
</template>

<script>
import { accountData, subscription, currencies, createCatalog, stores } from '@/graphql'

export default {
  apollo: { accountData, subscription, currencies, stores },
  data: () => ({
    valid: null,
    error: false,
    errorMessage: null,
    form: {
      name: null,
      currency: null
    },
    loading: false,
    required: [
        v => !!v || 'This field is required'
    ]
  }),
  methods: {
    createStore () {
      this.loading = true
      this.$apollo.mutate({
        mutation: createCatalog,
        variables: this.form
      }).catch((error) => {
        this.error = true
        this.errorMessage = error.message.replace('GraphQL error:', '')
        this.loading = false
      }).then((result) => {
        if (result) {
          const { hashId } = result.data.createCatalog.catalog
          this.$apollo.queries.stores.refetch().then(() => {
            this.$store.commit('setStore', {store: hashId})
            this.loading = false
            this.$router.push(`/inventory`)
          })
        }
      })
    }
  }
}
</script>